<template>
    <figure class="equipment-service-history" v-if="loaded">
        <simple-table :collection="serviceRequestIssues" :columns="equipmentServiceHistoryColumns" :model="model" :filtersOff="true"></simple-table>
    </figure>
</template>

<script>
import SimpleTable from '../tables/SimpleTable.vue'; 

import ServiceRequestIssueModel from '../../models/ServiceRequestIssueModel';
import EquipmentModel from '../../models/EquipmentModel';
import axiosInstance from '../../connections/AxiosInstance';

const equipmentServiceHistoryColumns = {
    'serviceRequest.status': {
        title: "Status",
        sortable: true,
    },
    'serviceRequest.id' : {
        title: "Service Request Number",
        sortable: true,
    },
    'serviceIssueType' : {
        title: "Issue",
        sortable: true
    },
    'serviceRequest.invoice.invoiceNumber' : {
        title : "Invoice #",
        sortable: true,
        link: '/invoices/',
        NA: 'Not Invoiced'
    },
    'serviceRequest.invoice.invoiceDate' : {
        title: "Invoice date",
        sortable: true,
        NA: '--',
    },
    'serviceRequest.invoice.purchaseOrderNumber' : {
        title: "PO Number",
        sortable: true,
        NA: '--'
    },
    'serviceRequest.invoice.total' : {
        title: "Amount",
        sortable: true,
        format: `money`
    },

}

export default {
    components: {
        SimpleTable,
            // FormatData
    },
    props: ['equipmentId'],
    data: function(){
        return {
            loaded: false,
            equipmentServiceHistoryColumns,
            model: new ServiceRequestIssueModel
        }
    },
    beforeCreate: function(){
        var $this = this;
        var p1 = axiosInstance.getRelated(ServiceRequestIssueModel, EquipmentModel, this.equipmentId);
        Promise.all([p1, ]).then(() => {
            $this.loaded = true;
        });
    },
    computed: {
        serviceRequestIssues(){
            return this.$store.state.AppData[ServiceRequestIssueModel.name].where(x => x.equipment.id === this.equipmentId);
        }
    }
}

</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";
</style>