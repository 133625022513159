<template>
    <content class="equipment-detail" v-if="loaded">
        <!-- <div class="controls">
            <a class="button-2"><i class="fas fa-download"></i></a>
            <a class="button-2"><i class="fas fa-print"></i></a>
            <router-link class="close button-2" to="/fleet/equipment"><i class="fas fa-times"></i></router-link>
        </div> -->
        <div class="details">
            {{equipment.trailerNumber}}
            <equipment-specification :equipment="equipment"></equipment-specification>
            <equipment-service-history :equipmentId="equipment.id"></equipment-service-history>
            <!-- <equipment-contract :contract="equipment"></equipment-contract> -->
        </div>
    </content>
</template>

<script>
import EquipmentServiceHistory from '../../components/equipment/EquipmentServiceHistory.vue';
import EquipmentSpecification from "../../components/equipment/EquipmentSpecification.vue"
// import EquipmentContract from "../../components/equipment/EquipmentContract.vue"

import EquipmentModel from '../../models/EquipmentModel';

export default {
    components: {
        EquipmentSpecification,
        // EquipmentContract
 
        EquipmentServiceHistory   },
    computed: {
        equipment: function(){
            return this.$store.state.AppData[EquipmentModel.name][this.$route.params.id];
        } 
    },
    data: function () {
        return {
            loaded: false,
            //Example Equipment List
        }
    },
    beforeCreate: function(){
                
        var $this = this;
        var p1 = this.$store.dispatch('get', {model: EquipmentModel, id: this.$route.params.id});
        Promise.all([p1]).then(() => {
            $this.loaded = true;
        });

    }

}
</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";

.equipment-detail{
    .equipment-specification,
    .equipment-contract
    {
        width: calc(50% - #{$space-standard * 2});
        flex: 0 1 auto;
    }

}
</style>